<template>
  <div>
    <v-container
      id="dashboard-view"
      fluid
      tag="section"
    >
      <v-row
        class="d-flex justify-center align-center"
        style="background: white"
      >
        <v-col
          cols="1"
          class="d-flex justify-center"
        >
          <div>
            <v-btn
              v-if="userType !== 4"
              color="primary"
              outlined
              text
              style="background: rgba(124, 226, 240, 0.57)"
              class="font-weight-bold text-h5"
              @click="$router.go(-1)"
            >
              {{ $t('table.messages.back') }}
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-center"
        >
          <div class="infoHeader">
            {{ $t('table.headers.manageId')
            }}<span>{{ parttimeInfo && parttimeInfo.manageId }}</span>
          </div>
        </v-col>
        <v-col
          cols="2"
          class="d-flex justify-center"
        >
          <div class="infoHeader">
            {{ $t('table.headers.name') }}<span>{{ parttimeInfo && parttimeInfo.user && parttimeInfo.user.name }}</span>
          </div>
        </v-col>
        <v-col
          cols="3"
          class="d-flex justify-center"
        >
          <div class="infoHeader">
            {{ $t('table.headers.furiganaName') }}<span>{{ parttimeInfo && parttimeInfo.furiganaName }}</span>
          </div>
        </v-col>
        <v-col
          cols="4"
          class="d-flex justify-center"
        >
          <div class="infoHeader">
            {{ $t('table.headers.workStartDate') }}
            <span>{{
              parttimeInfo &&
                parttimeInfo.createdAt &&
                moment(parttimeInfo.createdAt).format('YYYY/MM/DD')
            }}</span>
            <v-icon
              v-if="parttimeInfo && parttimeInfo.deletedAt"
              color="#5CA6D2"
              small
              class="ml-3 mr-3"
            >
              mdi-arrow-right
            </v-icon>
            <div v-if="parttimeInfo && parttimeInfo.deletedAt">
              {{ $t('table.headers.workEndDate') }}
              <span>{{
                moment(parttimeInfo.deletedAt).format('YYYY/MM/DD')
              }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <vue-highcharts
        :text="textChart"
      />
    </v-container>
    <v-container
      id="dashboard-view"
      fluid
      tag="section"
      class="pa-0"
    >
      <v-data-table
        v-if="$route.params.id"
        :headers="headers"
        :items="items"
        :search="search"
        :options.sync="options"
        :server-items-length="totalItems"
        class="elevation-1 "
        stle
        :loading-text="$t('table.messages.loading')"
        :loading="loading"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 15, 30, 50],
          showFirstLastPage: false,
          'items-per-page-text': '',
        }"
      >
        <template slot="no-data">
          {{ $t('table.messages.no_data') }}
        </template>
        <template
          v-slot:footer.page-text=""
          class="mr-0"
        >
          {{ $t('table.messages.page') }}
          {{ options.page }}
        </template>
        <template v-slot:top>
          <v-container class="mt-5">
            <v-row class="d-flex justify-end">
              <v-col cols="4">
                <div class="align-self-center">
                  <v-text-field
                    id="search"
                    v-model="search"
                    name="search pa-0"
                    :placeholder="$t('messages.search_history')"
                    outlined
                  />
                </div>
              </v-col>

              <v-col
                cols="2"
                class="d-flex align-end p-0"
              >
                <div class="mb-9">
                  <v-btn
                    class="h-100"
                    large
                    color="primary"
                    @click="onSearch"
                  >
                    <span class="pr-5 pl-5 font-weight-bold text-h5">
                      {{ $t('table.messages.search') }}</span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:item.index="props">
          {{ (options.page -1 )*options.itemsPerPage + props.index + 1 }}
        </template>
        <template v-slot:item.date="props">
          {{ moment(props.item.workdate).format('YYYY/MM/DD') }}
        </template>
        <template v-slot:item.shiftTime="props">
          <span :class="!props.item.actualStartAt ? 'absentText' : null">
            {{
              props.item.actualStartAt && props.item.actualEndAt
                ? `${props.item.shift.shiftStartAt.substring(0, 5)} ~ ${props.item.shift.shiftEndAt.substring(0, 5)}`
                : $t('chart.absent')
            }}
          </span>
        </template>
        <template v-slot:item.workHours="props">
          <span :class="!props.item.totalWorkHour ? 'absentText' : 'workHoursNum'">
            {{
              props.item.totalWorkHour > 0
                ? props.item.totalWorkHour
                : $t('chart.absent')
            }}
          </span>
        </template>
        <template v-slot:item.factory="props">
          {{ props.item.shift.job.customer.user.name || '' }}
        </template>
        <template v-slot:item.job="props">
          {{ props.item.shift.job.name }}
        </template>
        <template v-slot:item.position="props">
          {{ props.item.shift.job.jobType }}
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import moment from 'moment'
  import VueHighcharts from './VueHighcharts.vue'
  export default {
    name: 'ParttimerHistory',
    components: { VueHighcharts },
    data () {
      return {
        moment,
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        search: '',
        options: {},
        currentOptions: {},
        headers: [],
        info: {},
        items: [],
        customerList: [],
        loading: false,
        totalItems: 1,
        userType: 0,
        textChart: 'chart.workingHoursPartime',
        paramsId: null,
      }
    },
    computed: {
      ...get('parttimer', [
        'message',
        'status',
        'error',
        'totalCountWorkHistory',
        'parttimeInfo',
        'resultTimeShift',
      ]),
      ...get('customer', ['list', 'message', 'status', 'error', 'customerDetail']),
      ...get('user', ['profile']),
    },
    watch: {
      error (value) {
        this.showSnackBar(value)
      },
      totalCountWorkHistory (value) {
        this.totalItems = value
      },
      parttimeInfo (value) {
        this.info = value
      },
      resultTimeShift (value) {
        const data = [...value]
        this.$set(this, 'items', data)
      },
      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      search (value) {
        this.initialize(value)
      },
      list (value) {
        this.$set(this, 'customerList', value.data.customers)
      },
      status (value) {
        this.loading = value === 'loading'
      },
      totalCount (value) {
        this.totalItems = value
      },
    },
    created () {
      this.paramsId = this.$route.params.id ? this.$route.params.id : JSON.parse(localStorage.getItem('user')).detail.typeCorrespondId
      console.log(this.paramsId)
      if (localStorage.getItem('user')) {
        this.userType = JSON.parse(localStorage.getItem('user')).detail.type
      }
      this.$store.dispatch('user/getProfile')
    },
    mounted () {
      if (this.$route.query) {
        if (this.$route.query.success) this.showSnackBar('messages.success')
      }
      // setting table's headers
      this.headers = [
        {
          text: this.$t('table.headers.index'),
          align: 'center',
          value: 'index',
          class: 'font-weight-bold',
          width: '6rem',
          sortable: false,
        },
        {
          text: this.$t('messages.date'),
          align: 'start',
          value: 'date',
          class: 'font-weight-bold',
          width: '10rem',
          sortable: true,
        },
        {
          text: this.$t('table.headers.shiftTime'),
          align: 'start',
          value: 'shiftTime',
          class: 'font-weight-bold',
          width: '10rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.workingHour'),
          align: 'center',
          value: 'workHours',
          class: 'font-weight-bold',
          width: '8rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.customerName'),
          align: 'start',
          value: 'factory',
          class: 'font-weight-bold',
          width: '8rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.job'),
          align: 'start',
          value: 'job',
          class: 'font-weight-bold',
          width: '7rem',
          sortable: false,
        },
        {
          text: this.$t('table.headers.position'),
          align: 'start',
          value: 'position',
          class: 'font-weight-bold',
          width: '7rem',
          sortable: false,
        },
      ]
    },
    methods: {
      initialize (search = '') {
        const tmpOptions = { ...this.options }
        tmpOptions.limit = tmpOptions.itemsPerPage
        tmpOptions.offset = tmpOptions.page - 1
        if (this.search.trim()) {
          tmpOptions.keyword = this.search.trim()
          if (tmpOptions.sortDesc[0] || tmpOptions.sortDesc[0] === undefined) {
            tmpOptions.order = 'desc'
          } else {
            tmpOptions.order = 'asc'
          }
        }
        if (tmpOptions.sortDesc[0] || tmpOptions.sortDesc[0] === undefined) {
          tmpOptions.order = 'desc'
        } else {
          tmpOptions.order = 'asc'
        }
        delete tmpOptions.page
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('customer/getCustomers', tmpOptions)
        this.$store.dispatch('parttimer/getWorkTimeHistory', {
          id: this.paramsId,
          option: tmpOptions,
        })
      },
      onSearch () {
        this.initialize(this.search.trim())
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
  }
</script>
<style lang="sass" scoped>
.infoHeader
  font-size: 1rem
  font-weight: bold
  color: #5CA6D2
  span
    font-size: 0.8rem
    margin-left: 10px
    color: #464E5F
.absentText
  color: #E43E08
  font-weight: bold
.workHoursNum
  color: #4262FF
  font-weight: bold
</style>

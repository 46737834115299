<template>
  <section class="charts">
    <div class="position-absolute">
      <v-select
        v-model="optionChart"
        :items="itemsyear"
        class="d-flex flex-row-reverse custom-select"
        outlined
        @change="changeSelected"
      />
      <vue-highcharts :options="options" />
    </div>
  </section>
</template>
<script>
  import { get } from 'vuex-pathify'
  import moment from 'moment'
  import VueHighcharts from 'vue2-highcharts'
  import Highcharts from 'highcharts'

  export default {
    components: {
      VueHighcharts,
    },
    props: ['text'],
    data () {
      return {
        moment,
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        info: {},
        workTimeArr: [],
        breakTimesArr: [],
        itemsyear: [this.$t('chart.recently')],
        optionChart: this.$t('chart.recently'),
        options: {},
        userType: 0,
      }
    },
    computed: {
      ...get('parttimer', ['message', 'status', 'error', 'totalCount', 'parttimeInfo', 'workTimeList']),
      ...get('user', ['profile']),
    },
    watch: {
      error (value) {
        this.showSnackBar(value)
      },
      parttimeInfo (value) {
        const array = []
        const start = parseInt(moment(value.createdAt).format('YYYY'))
        const end = parseInt(value.deletedAt ? moment(value.deletedAt).format('YYYY') : moment().format('YYYY'))
        if (end && start <= end) {
          for (let i = end; i >= start; i--) {
            array.push(i)
          }
        }
        const newArr = array.map(item => item.toString().concat('年'))
        this.itemsyear = [...this.itemsyear, ...newArr]
      },
      workTimeList (value) {
        const data = [...value.timeline]
        const resultWorkTime = data.map(item => parseFloat(Number.parseFloat(item.total_worktime).toFixed(2)))
        const resultBreakTimes = data.map(item => parseInt(item.total_breaktime))
        this.$set(this, 'workTimeArr', resultWorkTime)
        this.$set(this, 'breakTimesArr', resultBreakTimes)
        this.getChartProperties()
      },
    },
    async created () {
      if (localStorage.getItem('user')) {
        this.userType = JSON.parse(localStorage.getItem('user')).detail.type
      }
      this.$store.dispatch('user/getProfile')
      const paramsId = this.$route.params.id ? this.$route.params.id : JSON.parse(localStorage.getItem('user')).detail.typeCorrespondId
      this.$store.dispatch('parttimer/getChartData', {
        type: 'type=m',
        id: paramsId,
      })
      this.getChartProperties()
    },
    methods: {
      getChartProperties () {
        const xAxisYear = {
          categories: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          tickColor: 'white',
          labels: {
            style: {
              fontSize: '14px',
            },
          },
        }
        const xAxisRecently = {
          categories: [
            moment().day(-84).format('M月D日～'),
            moment().day(-77).format('M月D日～'),
            moment().day(-70).format('M月D日～'),
            moment().day(-63).format('M月D日～'),
            moment().day(-56).format('M月D日～'),
            moment().day(-49).format('M月D日～'),
            moment().day(-42).format('M月D日～'),
            moment().day(-35).format('M月D日～'),
            moment().day(-28).format('M月D日～'),
            moment().day(-21).format('M月D日～'),
            moment().day(-14).format('M月D日～'),
            moment().day(-7).format('M月D日～')],
          tickColor: 'white',
          labels: {
            style: {
              fontSize: '13px',
            },
          },
        }
        this.options = {
          credits: {
            enabled: false,
          },
          chart: {
            margin: 80,
            marginLeft: 100,
            height: 500,
          },
          title: {
            text: this.$t('chart.workingChart'),
            align: 'left',
            style: {
              color: '#212121',
            },
          },
          xAxis: this.optionChart === this.$t('chart.recently') ? xAxisRecently : xAxisYear,
          yAxis: {
            gridLineDashStyle: 'longdash',
            tickAmount: 6,
            title: {
              text: null,
            },
            labels: {
              style: {
                fontSize: '16px',
              },
            },
          },
          labels: {
            items: [
              {
                html: `(${this.$t('chart.timeUnit')})`,
                style: {
                  left: '-50px',
                  top: '-40px',
                  color: '#B5B5C3',
                  fontSize: '16px',
                },
              },
            ],
          },
          series: [
            {
              type: 'column',
              name: this.text ? this.$t(this.text) : this.$t('chart.workingHours'),
              data: this.workTimeArr,
              pointWidth: 20,
              maxPointWidth: 20,
              borderRadius: 4,
              color: '#1BC4BD',
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: 14,
                  color: '#5CA6D2',
                },
                y: 5,
              },
            },
            {
              type: 'line',
              name: this.$t('chart.absentTimes'),
              data: this.breakTimesArr,
              color: '#3699FF',
              marker: {
                lineWidth: 3,
                lineColor: Highcharts.getOptions().colors[0],
                fillColor: 'white',
              },
            },
          ],
        }
      },
      changeSelected (value) {
        this.optionChart = value
        this.getChartProperties()
        const paramsId = this.$route.params.id ? this.$route.params.id : JSON.parse(localStorage.getItem('user')).detail.typeCorrespondId
        this.$store.dispatch('parttimer/getChartData', {
          type: this.optionChart === this.$t('chart.recently')
            ? 'type=m'
            : `type=y&time=${value.slice(0, -1)}`,
          id: paramsId,
        })
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
  }
</script>
<style lang="sass" scoped>
  .position-absolute
    margin-top: -55px
    .custom-select
      z-index:2
      width: 115px
      background: rgba(124, 226, 240, 0.57)
      top: 70px
      height: 55px
      float: right
      right: 20px
</style>

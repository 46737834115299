<template>
  <v-container id="dashboard-view">
    <div v-if="detailUsers.type === 4">
      <history />
    </div>
    <v-row v-else-if="detailUsers.type === 2">
      <v-row>
        <v-col cols="12">
          <section>
            <div class="position-relative">
              <div class="custom-filter-dashboad mr-2">
                <v-row>
                  <v-col cols="6">
                    <v-btn color="primary">
                      <span
                        class="text-h5 pr-3 pl-3"
                        @click="handleExportFile()"
                      >
                        {{ $t("messages.export_csv") }}
                      </span>
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          outlined
                          dense
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="date"
                        locale="ja-jn"
                        no-title
                        scrollable
                        type="month"
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="menu = false"
                        >
                          {{ $t("messages.cancel") }}
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          {{ $t("messages.ok") }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
              <vue-highcharts
                style="height: 100%; width: 100%"
                :highcharts="Highcharts"
                :options="optionsEmployee"
              />
            </div>
          </section>
        </v-col>
      </v-row>
    </v-row>
    <v-row v-else-if="detailUsers.type === 3">
      <v-col cols="12">
        <section>
          <div class="position-relative">
            <div class="custom-filter-dashboad mr-2">
              <v-row>
                <v-col cols="10">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="date"
                      locale="ja-jn"
                      no-title
                      scrollable
                      type="month"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        {{ $t("messages.cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="selectMonth"
                      >
                        {{ $t("messages.ok") }}
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <div>
              <vue-highcharts
                ref="chart"
                style="height: 100%; width: 100%"
                :options="optionsCustomer"
              />
            </div>
            <div>
              <vue-highcharts
                ref="chart"
                style="height: 100%; width: 100%; margin-top: 30px"
                :options="optionsWorker"
              />
            </div>
          </div>
        </section>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="10">
        <section>
          <div style="background: white">
            <v-row
              justify="end"
              class="pr-4"
            >
              <v-col cols="3">
                <v-select
                  v-model="optionChart"
                  :items="itemsType"
                  class="d-flex flex-row-reverse custom-select"
                  outlined
                  @change="onChangeType"
                />
              </v-col>
              <v-col cols="2">
                <v-select
                  v-if="optionChart !== this.$t('chart.quarter')"
                  v-model="optionYear"
                  :items="itemsyear"
                  item-text="text"
                  item-value="value"
                  class="d-flex flex-row-reverse custom-select"
                  outlined
                  @change="onChangeYear"
                />
              </v-col>
            </v-row>
          </div>
        </section>
        <section class="mt-3">
          <div class="position-absolute">
            <vue-highcharts
              style="height: 100%; width: 100%"
              :highcharts="Highcharts"
              :options="optionsChartJob"
            />
          </div>
        </section>
        <section class="mt-3">
          <div class="position-absolute">
            <vue-highcharts
              style="height: 100%; width: 100%"
              :highcharts="Highcharts"
              :options="optionsChartParttime"
            />
          </div>
        </section>
      </v-col>
      <v-col
        cols="2"
        class="staticals"
      >
        <v-row class="mt-5">
          <v-col class="d-flex">
            <v-chip
              class="pa-16 chip"
              color="#EEE5FF"
              text-color="#8A50FC"
              @click="$router.push('/employee-list')"
            >
              <span>
                {{ $t("chip.total-employee") }}
                <br>
                {{ statistics.employeeCount }}
              </span>

              <v-icon
                right
                x-large
              >
                mdi-account
              </v-icon>
            </v-chip>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col>
            <v-chip
              class="pa-16 align-center chip"
              color="#C9F7F5"
              text-color="#02312F"
              @click="$router.push('/parttimer-list')"
            >
              <span>
                {{ $t("chip.total-job-partime") }}
                <br>
                {{ statistics.parttimeCount }}
              </span>
              <v-icon
                right
                x-large
              >
                mdi-account-clock
              </v-icon>
            </v-chip>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col>
            <v-chip
              class="pa-16 align-center chip"
              color="#FFE2E6"
              text-color="#F65464"
              @click="$router.push('/customer-list')"
            >
              <span>
                {{ $t("chip.total-customer") }}
                <br>
                {{ statistics.customerCount }}
              </span>
              <v-icon
                right
                x-large
              >
                mdi-account-group
              </v-icon>
            </v-chip>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col>
            <v-chip
              class="pa-16 align-center chip"
              color="#FFF4DE"
              text-color="#634100"
              @click="$router.push('/jobs')"
            >
              <span>
                {{ $t("chip.total-job") }}
                <br>
                {{ statistics.jobsCount }}
              </span>
              <v-icon
                right
                x-large
              >
                mdi-electron-framework
              </v-icon>
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="requestDialog === true"
      class="mt-6"
    >
      <v-col style="background: white; border-radius: 10px">
        <v-row>
          <v-col>
            <v-chip
              class="ma-2"
              text-color="black"
              color="#ECB9F9"
            >
              <v-avatar>
                <v-icon>mdi-credit-card-outline</v-icon>
              </v-avatar>
            </v-chip>
            <span style="color: blue">{{
              $t("messages.requestUpdateBank")
            }}</span>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-dialog
              v-model="dialogEmail"
              max-width="700px"
            >
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="#5CA6D2"
                  v-bind="attrs"
                  dark
                  @click="dispatchDialogMail"
                >
                  {{ $t("button.settingEmail") }}
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <div class="ma-5">
                        <p>
                          口座情報を更新する際、下記のメールに自動的に送信します
                        </p>
                      </div>
                    </v-row>
                    <v-row
                      v-for="(input, index) in editedEmail.emails"
                      :key="index"
                      class="d-flex align-center"
                    >
                      <v-col
                        cols="5"
                        class="pl-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          ref="title"
                          rules="required"
                        >
                          <v-text-field
                            v-model="input.name"
                            outlined
                            :hide-details="true"
                            :label="$t('table.headers.personInCharge')"
                            validate-on-blur
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="5"
                        class="pl-0"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          ref="title"
                          rules="required|email"
                        >
                          <v-text-field
                            v-model="input.email"
                            :label="$t('messages.email')"
                            outlined
                            :hide-details="true"
                            validate-on-blur
                            :error-messages="errors[0]"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="1">
                        <div class="d-flex align-center justify-space-around">
                          <v-icon
                            v-show="
                              index || (!index && editedEmail.emails.length > 0)
                            "
                            class="fas fa-minus-circle"
                            size="30px"
                            @click="showDialogDeleteMail(index)"
                          >
                            mdi-close-circle
                          </v-icon>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="6"
                        class="d-flex justify-end"
                      >
                        <v-icon
                          class="fas fa-plus-circle"
                          color="#0F56B3"
                          size="30px"
                          @click="addMail()"
                        >
                          mdi-plus-circle
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-row class="d-flex justify-end mb-3">
                    <v-col cols="2">
                      <v-btn
                        block
                        color="primary"
                        type="submit"
                        @click="updateEmail"
                      >
                        <span class="text-h5 font-weight-bold">{{
                          $t("messages.save")
                        }}</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        block
                        color="primary"
                        @click="closeDialogMail"
                      >
                        <span class="text-h5 font-weight-bold">
                          {{ $t("messages.cancel") }}</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
            stle
            hide-default-footer
            disable-pagination
          >
            <template v-slot:[`item.index`]="props">
              <strong>
                {{ props.index + 1 }}
              </strong>
            </template>
            <template v-slot:[`item.manageId`]="props">
              <strong>
                {{ props.item.parttimeEmployeeLastInfo.manageId }}
              </strong>
            </template>
            <template v-slot:[`item.name`]="props">
              <strong>
                {{ props.item.parttimeEmployeeLastInfo.user.username }}
              </strong>
            </template>
            <template v-slot:[`item.uneditBank`]="props">
              <div class="d-flex flex-column ma-2">
                <span>
                  {{ props.item.parttimeEmployeeLastInfo.bank }}
                </span>
                <span>
                  {{ props.item.parttimeEmployeeLastInfo.bankBranch }}
                </span>
                <span>
                  {{ props.item.parttimeEmployeeLastInfo.cardNumber }}
                </span>
                <span>
                  {{ props.item.parttimeEmployeeLastInfo.accountHolder }}
                </span>
              </div>
            </template>
            <template v-slot:[`item.editedBank`]="props">
              <div class="d-flex flex-column ma-2">
                <span>
                  {{ props.item.bank }}
                </span>
                <span>
                  {{ props.item.bankBranch }}
                </span>
                <span>
                  {{ props.item.cardNumber }}
                </span>
                <span>
                  {{ props.item.accountHolder }}
                </span>
              </div>
            </template>
            <template v-slot:[`item.reason`]="props">
              {{ props.item.reason }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-row class="d-flex justify-center">
                <v-btn
                  color="#3699FF"
                  small
                  dark
                  min-width="100px"
                  @click="approachReq(item)"
                >
                  {{ $t("button.update") }}
                </v-btn>
              </v-row>
              <v-row class="d-flex justify-center mt-5">
                <v-btn
                  color="#BBBBC2"
                  min-width="100px"
                  small
                  @click="cancelReq(item)"
                >
                  {{ $t("button.cancel") }}
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t("messages.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="font-weight-bold text-h4">
          <v-row class="d-flex align-center justify-center">
            <v-col cols="1">
              <v-icon
                large
                color="blue darken-2"
              >
                mdi-delete-outline
              </v-icon>
            </v-col>
            <v-col cols="8">
              この操作は元に戻りません。 本当に削除してよろしいですか？
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-actions>
          <v-spacer />

          <v-btn
            class="text-h5"
            dark
            color="#E43E08"
            @click="removeMail"
          >
            {{ $t("messages.delete") }}
          </v-btn>
          <v-btn
            class="text-h5"
            @click="
              dialogDelete = false;
              itemMailDelete = null;
            "
          >
            {{ $t("messages.cancel") }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import moment from 'moment'
  import VueHighcharts from 'vue2-highcharts'
  import Highcharts from 'highcharts'
  import { ValidationProvider, extend } from 'vee-validate'
  import { required, email } from 'vee-validate/dist/rules'
  import History from './Parttimer/History.vue'
  import XLSX from 'xlsx'

  export default {
    name: 'DashboardView',
    components: {
      VueHighcharts,
      ValidationProvider,
      History,
    },
    data () {
      return {
        Highcharts: Highcharts,
        dataChart: [],
        totalPlannedsEmployee: [],
        totalActualsEmployee: [],
        categories: [],
        moment,
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        info: {},
        itemsType: [this.$t('chart.quarter'), this.$t('chart.year')],
        itemsyear: [],
        optionChart: this.$t('chart.quarter'),
        optionYear: moment().year(),
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'center',
            value: 'index',
            class: 'font-weight-bold',
            sortable: false,
            width: '6rem',
          },
          {
            text: this.$t('table.headers.manageIdDashboard'),
            align: 'center',
            value: 'manageId',
            class: 'font-weight-bold',
            sortable: false,
            width: '14rem',
          },
          {
            text: this.$t('table.headers.name'),
            align: 'center',
            value: 'name',
            class: 'font-weight-bold',
            sortable: false,
            width: '14rem',
          },
          {
            text: this.$t('table.headers.uneditBank'),
            align: 'center',
            value: 'uneditBank',
            class: 'font-weight-bold',
            sortable: false,
            width: '20rem',
          },
          {
            text: this.$t('table.headers.editedBank'),
            align: 'center',
            value: 'editedBank',
            class: 'font-weight-bold',
            sortable: false,
            width: '20rem',
          },
          {
            text: this.$t('table.headers.reasonUpdateBank'),
            align: 'center',
            value: 'reason',
            class: 'font-weight-bold',
            sortable: false,
            width: '14rem',
          },
          {
            text: '',
            align: 'center',
            value: 'actions',
            class: 'font-weight-bold',
            sortable: false,
            width: '14rem',
          },
        ],
        items: [],
        dialogEmail: false,
        editedEmail: {
          emails: [{ name: '', email: '' }],
        },
        mailsRemoved: [],
        requestDialog: false,
        optionsChartJob: {},
        optionsChartParttime: {},
        userType: 0,
        dialogDelete: false,
        itemMailDelete: null,
        year: null,
        detailUsers: null,
        date: new Date().toISOString().substr(0, 7),
        menu: false,
        optionsCustomer: {},
        optionsWorker: {},
        optionsEmployee: {},
        listActual: [],
        listPlanned: [],
        listWorkerActual: [],
        listWorkerPlanned: [],
        ws_data: [],
        ws_data2: [],
      }
    },
    computed: {
      ...get('totalCount', [
        'totalJobsPartime',
        'totalEmployees',
        'totalCustomers',
        'totalJobs',
      ]),
      ...get('parttimer', [
        'message',
        'status',
        'error',
        'requestBankList',
        'mailsList',
      ]),
      ...get('dashboard', [
        'totalActuals',
        'totalPlanneds',
        'getJobsData',
        'getJobParttimesData',
        'jobParttimesData',
        'getLabelJob',
        'statistics',
        'totalWorkerActual',
        'totalWorkerPlanned',
      ]),
      ...get('employee', ['message', 'status', 'error', 'dataDashboadFulltime']),
    },
    watch: {
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      message (value) {
        if (value === 'success') {
          this.showSnackBar('messages.success')
        } else if (value === 'updateMailSuccess') {
          this.showSnackBar('messages.updateMailSuccess')
        } else if (value === 'cancelSuccess') {
          this.showSnackBar('messages.cancelSuccess')
        }
      },
      totalActuals (value) {
        this.listActual = value
      },
      totalPlanneds (value) {
        this.listPlanned = value
      },
      totalWorkerActual (value) {
        this.listWorkerActual = value
      },
      totalWorkerPlanned (value) {
        this.listWorkerPlanned = value
      },
      requestBankList (value) {
        if (value.count > 0) this.requestDialog = true
        this.items = value.rows
      },
      mailsList (value) {
        const { rows } = value
        this.editedEmail.emails = rows.length ? rows : [{ name: '', email: '' }]
        this.dialogEmail = true
      },
      dataDashboadFulltime (value) {
        const workTimeForDateActuals = []
        let totalAllPlanned = 0
        let totalAllActual = 0
        let totalDiff = 0
        let totalRate = 0
        const totalIndex = value.customers.length

        const totalPlanneds = []
        const totalActuals = []
        const categories = []
        const wsData = [
          ['工場数', '総計', '注文', '出勤', '欠勤', '率'],
          [0, '', 0, 0, 0, 0],
          ['No', '工場名', '注文', '出勤', '欠勤', '率'],
        ]

        const ws2Data = [
          [
            'No',
            '工場名',
            '注文名数',
            '出勤名数',
            '達成率',
            '日付',
            '１日',
            '２日',
            '３日',
            '４日',
            '５日',
            '６日',
            '７日',
            '８日',
            '９日',
            '１０日',
            '１１日',
            '１２日',
            '１３日',
            '１４日',
            '１５日',
            '１６日',
            '１７日',
            '１８日',
            '１９日',
            '２０日',
            '２１日',
            '２２日',
            '２３日',
            '２４日',
            '２５日',
            '２６日',
            '２７日',
            '２８日',
            '２９日',
            '３０日',
            '31日',
          ],
          [
            '',
            '',
            '',
            '',
            '',
            '曜日',
            '水',
            '木',
            '金',
            '土',
            '日',
            '月',
            '火',
            '水',
            '木',
            '金',
            '土',
            '日',
            '月',
            '火',
            '水',
            '木',
            '金',
            '土',
            '日',
            '月',
            '火',
            '水',
            '木',
            '金',
            '土',
            '日',
            '月',
            '火',
            '水',
            '木',
            '金',
          ],
        ]
        value.customers.forEach((customer, index) => {
          const totalPlannedFlowDates = [
            index + 1,
            customer.name,
            customer.totalPlanned,
            customer.totalActual,
            customer.rate,
            '注文',
          ]

          const totalActualFlowDates = ['', '', '', '', '', '出勤']
          const totalDiffFlowDates = ['', '', '', '', '', '欠勤']
          const actualFlowDates = []

          totalPlanneds.push(customer.totalPlanned)
          totalActuals.push(customer.totalActual)
          categories.push(customer.name)
          wsData.push([
            index + 1,
            customer.name,
            customer.totalPlanned,
            customer.totalActual,
            customer.diff,
            customer.rate,
          ])
          totalAllPlanned += customer.totalPlanned
          totalAllActual += customer.totalActual
          totalDiff += customer.diff
          customer.workTimeForDate.forEach((workTime) => {
            totalPlannedFlowDates.push(workTime.totalPlanned)
            totalActualFlowDates.push(workTime.totalActual)
            totalDiffFlowDates.push(workTime.diff)
            actualFlowDates.push(workTime.totalActual)
          })

          ws2Data.push(totalPlannedFlowDates)
          workTimeForDateActuals.push(actualFlowDates)
          ws2Data.push(totalActualFlowDates)
          ws2Data.push(totalDiffFlowDates)
        })
        totalRate =
          totalAllActual / totalAllPlanned
            ? ((totalAllActual / totalAllPlanned) * 100).toFixed(2)
            : 0
        wsData[1] = [
          totalIndex,
          '',
          totalAllPlanned,
          totalAllActual,
          totalDiff,
          totalRate,
        ]
        this.ws_data = wsData
        this.ws_data2 = ws2Data
        this.totalPlannedsEmployee = totalPlanneds
        this.totalActualsEmployee = totalActuals
        this.categories = categories
        this.getChartPropertiesDashboadFulltime()
      },
      date (value) {
        if (value && this.userType === 2) {
          this.getDataDashboadFulltime()
        }
      },
    },
    async created () {
      this.getChartProperties()
      this.getChartPropertiesDashboadFulltime()
      if (localStorage.getItem('user')) {
        this.userType = JSON.parse(localStorage.getItem('user')).detail.type
        this.detailUsers = JSON.parse(localStorage.getItem('user')).detail
      }
      const params = {
        id: this.detailUsers?.typeCorrespondId,
        date: this.date,
      }
      switch (this.detailUsers.type) {
        case 3:
          this.optionsCustomer = this.getDataChart([], [])
          this.optionsWorker = this.getDataChart([], [], true)
          await this.$store.dispatch('dashboard/getDashboardCustomers', params)
          this.optionsCustomer = this.getDataChart(
            this.listActual,
            this.listPlanned,
          )
          this.optionsWorker = this.getDataChart(
            this.listWorkerActual,
            this.listWorkerPlanned,
            true,
          )
          break
        case 4:
          break
        default:
          this.$store.dispatch('totalCount/getTotalCountParttimer')
          this.$store.dispatch('totalCount/getTotalCountEmployees')
          this.$store.dispatch('totalCount/getTotalCountCustomers')
          this.$store.dispatch('parttimer/getRequestBank')
          this.$store.dispatch('dashboard/getMainStatistics')
          await this.$store.dispatch('dashboard/getWorkTimeAdmin')
          this.getChartProperties()
          break
      }
    },
    mounted () {
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })

      extend('email', {
        ...email,
        message: this.$t('validator.email_invalid'),
      })
      const year = moment().year()
      this.itemsyear = [2, 1, 0].map((i) => {
        return {
          text: year - i + '年',
          value: year - i,
        }
      })
      if (this.userType === 2) {
        this.getDataDashboadFulltime()
      }
    },
    methods: {
      getDataDashboadFulltime () {
        const payload = {
          id: JSON.parse(localStorage.getItem('user')).detail.id,
          date: this.date,
        }
        this.$store.dispatch('employee/getDataDashboadFulltime', payload)
      },
      handleExportFile () {
        const ws = XLSX.utils.aoa_to_sheet(this.ws_data)
        const ws2 = XLSX.utils.aoa_to_sheet(this.ws_data2)

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'SheetJS1')
        XLSX.utils.book_append_sheet(wb, ws2, 'SheetJS2')
        XLSX.writeFile(wb, 'sheetjs.xlsx')
      },
      getDataChart (listActual, listPlanned, isWorker = false) {
        const arrCategories = []
        const daysInMonth = moment(this.date).daysInMonth()
        for (let i = 1; i <= daysInMonth; i++) {
          arrCategories.push(i + '日')
        }
        return {
          credits: {
            enabled: false,
          },
          chart: {
            height: 400,
          },
          title: {
            text: isWorker ? this.$t('chart.totalNumberOfWorker') : '総労働時間',
            align: 'left',
            style: {
              color: '#2d89bf',
              marginBottom: 10,
            },
          },
          xAxis: {
            categories: arrCategories,
            tickColor: 'white',
            labels: {
              style: {
                fontSize: '13px',
              },
            },
          },
          yAxis: {
            title: {
              text: '',
            },
            labels: {
              style: {
                fontSize: '16px',
              },
            },
          },
          series: [
            {
              type: 'column',
              name: isWorker
                ? this.$t('messages.plannedNumberOfPeople')
                : this.$t('table.headers.plannedTime'),
              data: listPlanned,
              pointWidth: 10,
              maxPointWidth: 10,
              borderRadius: 4,
              color: '#7fa7f5',
              dataLabels: {
                enabled: false,
                style: {
                  fontSize: 14,
                  color: '#7fa7f5',
                },
                y: 5,
              },
            },
            {
              type: 'column',
              name: isWorker
                ? this.$t('chart.workingHours')
                : this.$t('messages.commutingTime'),
              data: listActual,
              pointWidth: 10,
              maxPointWidth: 10,
              borderRadius: 4,
              color: '#e97e1c',
              dataLabels: {
                enabled: false,
                style: {
                  fontSize: 14,
                  color: '#e97e1c',
                },
                y: 5,
              },
            },
          ],
        }
      },
      async selectMonth () {
        const params = {
          id: this.detailUsers?.typeCorrespondId,
          date: this.date,
        }
        await this.$store.dispatch('dashboard/getDashboardCustomers', params)
        this.$refs.menu.save(this.date)
        this.optionsCustomer = this.getDataChart(
          this.listActual,
          this.listPlanned,
        )
        this.optionsWorker = this.getDataChart(
          this.listWorkerActual,
          this.listWorkerPlanned,
          true,
        )
      },
      getChartProperties () {
        const xAxisYear = {
          categories: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月',
          ],
          tickColor: 'white',
          labels: {
            style: {
              fontSize: '14px',
            },
          },
        }
        const xAxisRecently = {
          categories: this.getLabelJob,
          tickColor: 'white',
          labels: {
            style: {
              fontSize: '13px',
            },
          },
        }
        this.optionsChartJob = {
          credits: {
            enabled: false,
          },
          chart: {
            height: 400,
            maxWith: 1340,
          },
          title: {
            text: this.$t('chart.totalJob'),
            align: 'center',
            style: {
              color: '#2d89bf',
              marginBottom: 10,
            },
          },
          xAxis:
            this.optionChart === this.$t('chart.quarter')
              ? xAxisRecently
              : xAxisYear,
          yAxis: {
            title: {
              text: '',
            },
            labels: {
              style: {
                fontSize: '16px',
              },
            },
          },
          labels: {
            items: [
              {
                html: `(${this.$t('chart.work')})`,
                style: {
                  left: '0px',
                  top: '-40px',
                  color: '#B5B5C3',
                  fontSize: '16px',
                },
              },
            ],
          },
          series: [
            {
              type: 'column',
              name: this.$t('chart.jobChart'),
              data: this.getJobsData,
              pointWidth: 20,
              maxPointWidth: 20,
              borderRadius: 4,
              color: '#5CA6D2',
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: 14,
                  color: '#5CA6D2',
                },
                y: 5,
              },
            },
          ],
        }
        this.optionsChartParttime = {
          credits: {
            enabled: false,
          },
          chart: {
            height: 400,
            maxWith: 1340,
          },
          title: {
            text: this.$t('chart.totalJobParttime'),
            align: 'center',
            style: {
              color: '#2d89bf',
            },
          },
          xAxis:
            this.optionChart === this.$t('chart.quarter')
              ? xAxisRecently
              : xAxisYear,
          yAxis: {
            title: {
              text: '',
            },
            labels: {
              style: {
                fontSize: '16px',
              },
            },
          },
          labels: {
            items: [
              {
                html: `(${this.$t('chart.people')})`,
                style: {
                  left: '0px',
                  top: '-40px',
                  color: '#B5B5C3',
                  fontSize: '16px',
                },
              },
            ],
          },
          series: [
            {
              type: 'column',
              name: this.$t('chart.countVisaAbove30Days'),
              data: this.jobParttimesData.map((i) => {
                return i.countVisaAbove30Days
              }),
              pointWidth: 20,
              maxPointWidth: 20,
              borderRadius: 4,
              color: '#1BC4BD',
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: 14,
                  color: '#1BC4BD',
                },
                y: 5,
              },
            },
            {
              type: 'column',
              name: this.$t('chart.countVisaExpired'),
              data: this.jobParttimesData.map((i) => {
                return i.countVisaExpired
              }),
              pointWidth: 20,
              maxPointWidth: 20,
              borderRadius: 4,
              color: '#DE3163',
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: 14,
                  color: '#DE3163',
                },
                y: 5,
              },
            },
            {
              type: 'column',
              name: this.$t('chart.countVisaUnder30Days'),
              data: this.jobParttimesData.map((i) => {
                return i.countVisaUnder30Days
              }),
              pointWidth: 20,
              maxPointWidth: 20,
              borderRadius: 4,
              color: '#007BA7',
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: 14,
                  color: '#007BA7',
                },
                y: 5,
              },
            },
          ],
        }
      },
      async onChangeType (value) {
        this.optionYear = moment().year()
        await this.$store.dispatch(
          'dashboard/getWorkTimeAdmin',
          this.optionChart === this.$t('chart.quarter')
            ? {}
            : { year: this.optionYear },
        )
        this.getChartProperties()
      },
      async onChangeYear (value) {
        const params = {
          year: value,
        }
        await this.$store.dispatch('dashboard/getWorkTimeAdmin', params)

        this.getChartProperties()
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      // method accept and cancel request
      approachReq (value) {
        const payload = {
          id: value.parttimeEmployeeId,
          isApprove: true,
        }
        this.$store.dispatch('parttimer/approachReq', payload)
        setTimeout(() => {
          this.$store.dispatch('parttimer/getRequestBank')
        }, 300)
      },
      cancelReq (value) {
        const payload = {
          id: value.parttimeEmployeeId,
          isApprove: false,
        }
        this.$store.dispatch('parttimer/cancelReq', payload)
        setTimeout(() => {
          this.$store.dispatch('parttimer/getRequestBank')
        }, 300)
      },
      // Dialog email
      dispatchDialogMail () {
        this.$store.dispatch('parttimer/getmailsList')
      },
      addMail () {
        const filter =
          /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        const found = this.editedEmail.emails.find(
          (item) => item.email.length === 0,
        )
        const found2 = this.editedEmail.emails.find(
          (item) => !filter.test(item.email),
        )
        if (!found) {
          if (!found2) {
            this.editedEmail.emails.push({ name: '', email: '' })
          }
        }
      },
      showDialogDeleteMail (id) {
        this.itemMailDelete = id
        this.dialogDelete = true
      },
      removeMail () {
        const index = this.itemMailDelete
        const itemRemove = this.editedEmail.emails.splice(index, 1)
        if (itemRemove) {
          itemRemove[0].deletedAt = moment.utc().format()
          this.mailsRemoved.push(itemRemove[0])
        }
        this.dialogDelete = false
        this.itemMailDelete = null
      },
      updateEmail () {
        const payload =
          this.mailsRemoved.length > 0
            ? [...this.editedEmail.emails, ...this.mailsRemoved]
            : [...this.editedEmail.emails]
        this.$store.dispatch('parttimer/updateMailsList', payload)
        this.dialogEmail = false
      },
      closeDialogMail () {
        this.editedEmail.emails = this.mailsList.rows
        this.dialogEmail = false
      },
      getChartPropertiesDashboadFulltime () {
        this.optionsEmployee = {
          credits: {
            enabled: false,
          },
          chart: {
            height: 400,
          },
          title: {
            text: '注文出勤図',
            align: 'left',
            style: {
              color: '#2d89bf',
              marginBottom: '10px',
            },
          },
          xAxis: {
            categories: this.categories,
            tickColor: 'white',
            labels: {
              style: {
                fontSize: '13px',
              },
            },
          },
          yAxis: {
            title: {
              text: '',
            },
            labels: {
              style: {
                fontSize: '16px',
              },
            },
          },
          series: [
            {
              type: 'column',
              name: '注文',
              data: this.totalPlannedsEmployee,
              pointWidth: 10,
              maxPointWidth: 10,
              borderRadius: 4,
              color: '#7fa7f5',
              dataLabels: {
                enabled: false,
                style: {
                  fontSize: 14,
                  color: '#5CA6D2',
                },
                y: 5,
              },
            },
            {
              type: 'line',
              name: '出勤',
              data: this.totalActualsEmployee,
              pointWidth: 10,
              maxPointWidth: 10,
              borderRadius: 4,
              color: '#e97e1c',
              dataLabels: {
                enabled: false,
                style: {
                  fontSize: 14,
                  color: '#5CA6D2',
                },
                y: 5,
              },
            },
          ],
        }
      },
      getTotalActual (data) {
        let totalActualFlowDate = []
        data.forEach((workTimeForDateActual) => {
          if (totalActualFlowDate.length === 0) {
            totalActualFlowDate = workTimeForDateActual
          } else {
            workTimeForDateActual.forEach((item, index) => {
              totalActualFlowDate[index] = totalActualFlowDate[index] + item
            })
          }
        })

        return totalActualFlowDate
      },
      getTotal (datas) {
        let total = 0
        datas.forEach((data) => {
          total += data
        })
        return total / 30
      },
    },
  }
</script>
<style lang="sass" scoped>
.position-absolute
  .custom-select
    z-index: 2
    width: 115px
    background: rgba(124, 226, 240, 0.57)
    top: 70px
    height: 55px
    float: right
    right: 20px
  .custom-select-customer
    top: 50px
.custom-filter-dashboad
  position: absolute
  top: 5px
  right: 0
  z-index: 2
.staticals
  background: white
  border-radius: 20px
  .v-chip
    width: 100%
    .v-icon
      position: absolute
      right: 15%
</style>
